<template>
  <div class="footer">
    <div class="content">
      <div class="list flex_b">
        <div class="item" v-for="item in menuList" :key="item.id">
            <h3>{{ item.title }}</h3>
          <p v-for="items in item.list" :key="items.title">
            <a :href="item.href" style="color:#888">{{ items.title }}
            </a>
            </p>
        </div>
        <div class="code">
          <h3>全国咨询热线</h3>
          <a href="tel:0431-8088 7187">0431-8088 7187</a>
          <div><img src="/code.png" alt="" /></div>
        </div>
      </div>
      <div class="text">
        网站备案号(
        <a href="https://beian.miit.gov.cn/" target="_blank">吉ICP备2023003150号 </a>
        )
        <span class="text-primary">
          
        </span>
      </div>
    </div>
    <f-link v-if="!notLink" />
  </div>
</template>
<script>
import fLink from "@/components/f-link";
export default {
  components: {
    fLink,
  },
  props: {
    notLink: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      menuList: [
        {
          id: 1,
          title: "公司",
          list: [{ id: 11, title: "关于我们" }],
          href:"https://www.huixianggongye.com/me",
        },
        {
          id: 2,
          title: "常见问题",href:"https://www.huixianggongye.com/me",
          list: [
            { id: 21, title: "联系我们" ,
            href:"tel:0431-8088 7187",
            },
          ],
        },
        {
          id: 3,
          title: "合作单位",
          list: [
            { id: 31, title: "长春市绿园区人民政府", href:"http://www.luyuan.gov.cn/" },
          ], href:"http://www.luyuan.gov.cn/"
        }
      ],
    };
  },
};
</script>
<style lang="scss">
.footer {
  background: url(../../assets/images/public/footer_back.jpg) no-repeat center;
  background-size: cover;
  .content {
    .list {
      padding-top: 70px;
      .item {
        width: 18%;
        h3 {
          font-size: 20px;
          font-weight: 500;
          color: white;
        }
        p {
          margin-top: 15px;
          color: #666666;
          font-size: 14px;
          &:first-child {
            margin-top: 25px;
          }
        }
      }
      .code {
        width: 28%;
        text-align: right;
        h3 {
          font-size: 16px;
          font-weight: 500;
          color: white;
        }
        a {
          display: block;
          margin: 25px 0;
          font-size: 30px;
          line-height: 1;
          color: white;
        }
        p {
          font-size: 14px;
          line-height: 25px;
          color: white;
        }
        div {
          width: 95px;
          display: inline-block;
          margin-top: 20px;
          img {
            width: 100%;
          }
        }
      }
    }
    .text {
      border-top: 1px solid #666666;
      margin-top: 50px;
      padding: 50px 0;
      text-align: center;
      font-size: 14px;
      color: #888888;
      a {
        color: #888888;
      }
    }
  }
}
@media (max-width:1000px) {
  .footer {
  background: url(../../assets/images/public/footer_back.jpg) no-repeat center;
  background-size: cover;
  .content {
    .list {
      padding-top: 5vw;
      flex-wrap: wrap;
      .item {
        width: 25%;
        h3 {
          font-size: 4vw;
        }
        p {
          margin-top: 2vw;
          color: #666666;
          font-size: 3vw;
          &:first-child {
            margin-top: 2vw;
          }
        }
      }
      .code {
        width: 50%;
        text-align: left;
        margin-top: 5vw;
        h3 {
          font-size: 4vw;
          font-weight: 500;
          color: white;
        }
        a {
          display: block;
          margin: 2vw 0;
          font-size: 5vw;
          line-height: 1;
          color: white;
        }
        p {
          font-size: 3vw;
          line-height: 6vw;
          color: white;
        }
        div {
          width: 25vw;
          display: inline-block;
          margin-top: 2vw;
          img {
            width: 100%;
          }
        }
      }
    }
    .text {
      border-top: 1px solid #666666;
      margin-top: 2vw;
      padding: 5vw 0;
      text-align: center;
      font-size: 3vw;
      color: #888888;
      a {
        color: #888888;
      }
    }
  }
}
}
</style>