import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import Layout from '@/views/public/index.vue'
import Layout2 from '@/views/competition/index.vue'
const routes = [{
    path: '/',
    name: '首页',
    component: Layout,
    children: [{
      path: 'home',
      name: '供需大厅 -惠享工业企业服务平台',
      component: () => import('@/views/make/index.vue')
    }, {
      path: '',
      name: '惠享工业企业服务平台',
      component: () => import('@/views/home/index.vue'),
     
    }, {
      path: 'supplier/list',
      name: '平台资讯-惠享工业企业服务平台',
      component: () => import('@/views/supplier/list.vue')
    }, {
      path: 'match/list',
      name: '展会赛事-惠享工业企业服务平台',
      component: () => import('@/views/match/list.vue')
    },  {
      path: 'company/list',
      name: '企业大厅-惠享工业企业服务平台',
      component: () => import('@/views/company/list.vue')
    }, {
      path: 'service/list',
      name: '招标大厅-惠享工业企业服务平台',
      component: () => import('@/views/service/list.vue')
    }, {
      path: 'service/detail',
      name: '需求详情-惠享工业企业服务平台',
      component: () => import('@/views/service/detail.vue')
    }, {
      path: 'match/detail',
      name: '赛事详情-惠享工业企业服务平台',
      component: () => import('@/views/match/detail.vue')
    }, {
      path: 'supplier/detail',
      name: '资讯详情-惠享工业企业服务平台',
      component: () => import('@/views/supplier/detail.vue')
    }, {
      path: 'zhuanjia/list',
      name: '专家展示-惠享工业企业服务平台',
      component: () => import('@/views/zhuanjia/list.vue')
    }, {
      path: 'zhuanjia/detail',
      name: '专家详情-惠享工业企业服务平台',
      component: () => import('@/views/zhuanjia/detail.vue')
    }, {
      path: 'keji/list',
      name: '成果转化-惠享工业企业服务平台',
      component: () => import('@/views/keji/list.vue')
    }, {
      path: 'keji/detail',
      name: '成果转化-惠享工业企业服务平台',
      component: () => import('@/views/keji/detail.vue')
    },
    
    {
      path: 'xuqiuduijie/list',
      name: '需求对接-惠享工业企业服务平台',
      component: () => import('@/views/xuqiuduijie/list.vue')
    }, {
      path: 'xuqiuduijie/detail',
      name: '需求对接-惠享工业企业服务平台',
      component: () => import('@/views/xuqiuduijie/detail.vue')
    },
    
     {
      path: 'servers/list',
      name: '热门服务-惠享工业企业服务平台',
      component: () => import('@/views/servers/list.vue')
    }, {
      path: 'design',
      name: '工业设计平台-惠享工业企业服务平台',
      component: () => import('@/views/design/index.vue')
    }, {
      path: 'solution',
      name: '行业解决方案-惠享工业企业服务平台',
      component: () => import('@/views/solution/index.vue')
    }, {
      path: 'support',
      name: '惠享学院-惠享工业企业服务平台',
      component: () => import('@/views/support/index.vue')
    }, {
      path: 'support/detail',
      name: '惠享学院详情-惠享工业企业服务平台',
      component: () => import('@/views/support/detail.vue')
    }, {
      path: 'me',
      name: '关于我们-惠享工业企业服务平台',
      component: () => import('@/views/about/index.vue')
    }]
  },{
    path: '/competition',
    name: '大赛',
    component: Layout2,
    children: [{
      path: '',
      name: '大赛首页-竞赛在线-惠享工业企业服务平台',
      component: () => import('@/views/competition/home.vue'),
    },{
      path: 'news',
      name: '赛事动态-竞赛在线-惠享工业企业服务平台',
      component: () => import('@/views/competition/news.vue'),
    },{
      path: 'show',
      name: '作品展示-竞赛在线-惠享工业企业服务平台',
      component: () => import('@/views/competition/show.vue'),
    },{
      path: 'specialist',
      name: '评审专家团-竞赛在线-惠享工业企业服务平台',
      component: () => import('@/views/competition/specialist.vue'),
    },{
      path: 'flow',
      name: '报名流程-竞赛在线-惠享工业企业服务平台',
      component: () => import('@/views/competition/flow.vue'),
    },{
      path: 'showDetail',
      name: '作品详情-竞赛在线-惠享工业企业服务平台',
      component: () => import('@/views/competition/showDetail.vue'),
    },{
      path: 'newsDetail',
      name: '动态详情-竞赛在线-惠享工业企业服务平台',
      component: () => import('@/views/competition/newsDetail.vue'),
    }]
  },
  {
    path: '/login',
    name: '登录-惠享工业企业服务平台',
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/register',
    name: '注册-惠享工业企业服务平台',
    component: () => import('@/views/login/register.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router