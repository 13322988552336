import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    registerUrl: `${window.config.api}/account/registerUser?yzType=1`,
    loginUrl: `${window.config.api}/a/login?yzType=1`,
    userInfo: {},
    isWap: $(window).width() < 1000,
    keyword: ""
  },
  mutations: {
    anime() {
      let h = $(window)[0].innerHeight;
      $(".anime")
        .not(".anime-show")
        .each(function () {
          if (
            $(window).scrollTop() >= $(this).offset().top - h * 0.9 ||
            $(this).offset().top < h
          ) {
            $(this).addClass("anime-show");
            var _this = this;
            setTimeout(function () {
              $(_this)
                .find(".anime-list-opacity")
                .addClass("anime-opacity-show");
            }, 1000);
          }
        });
      $(".anime-left")
        .not(".anime-show")
        .each(function () {
          let _top = $(this).offset().top;
          $(this)
            .find(".anime-left-list")
            .each(function () {
              if (
                $(window).scrollTop() >= _top - h * 0.8 ||
                $(this).offset().top < h
              ) {
                $(this).addClass("anime-show");
              }
            });
        });
      $(".anime-delay")
        .not(".anime-show")
        .each(function () {
          let _top = $(this).offset().top;
          $(this)
            .find(".anime-delay-list")
            .each(function () {
              if (
                $(window).scrollTop() >= _top - h * 0.8 ||
                $(this).offset().top < h
              ) {
                $(this).addClass("anime-show");
              }
            });
        });
    },
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    setKeyword(state, data) {
      state.keyword = data;
    },
  },
  actions: {},
  modules: {

  }
})