<template>
  <div :class="['header', $route.fullPath == '/home' ? '' : '']">
    <div v-if="!isWap" :class="['header-main', isDown ? 'on' : '']">
      <div class="bj"></div>
      <div class="content anime flex_b flex_align_c">
        <div class="logo">
          <a href="/">
            <img
              v-if="this.$route.fullPath == '/home'"
              src="@/assets/images/public/logo2.png"
              alt=""
            />
            <img v-else src="@/assets/images/public/logo2.png" alt="" />
          </a>
        </div>
        <div class="location">
          <el-popover placement="bottom" width="300" trigger="click">
            <el-row :gutter="5" class="location-list">
              <el-col :span="6" v-for="item in locationList" :key="item">
                <div
                  :class="['item', location == item ? 'on' : '']"
                  @click="location = item"
                >
                  {{ item }}
                </div>
              </el-col>
            </el-row>
            <div slot="reference" class="text">
              {{ location }} <span class="el-icon-caret-bottom"></span>
            </div>
          </el-popover>
        </div>
        <div class="menu-and-search">
          <div class="menu flex">
            <div
              :class="['item', hasActive(item.url) ? 'on' : '']"
              v-for="item in menuList"
              :key="item.id"
            >
              <a :href="item.url">{{ item.title }}</a>
            </div>
          </div>
          <div class="search">
            <el-select v-model="select" placeholder="请选择">
              <el-option label="供需大厅" value="1"></el-option>
              <el-option label="企业大厅" value="2"></el-option>
              <el-option label="平台资讯" value="3"></el-option>
              <el-option label="惠享学院" value="4"></el-option>
              <el-option label="展会赛事" value="5"></el-option>
            </el-select>
            <el-input
              placeholder="关键词搜索"
              v-model="keyword"
              class="input-with-select"
            >
              <el-button
                slot="append"
                @click="goSearch"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </div>
        </div>
        <div class="login flex flex_align_c" v-if="hasLogin == 0">
          <span class="el-icon-user-solid"></span>
          <div>
            <a :href="$store.state.loginUrl">登录</a> /
            <a :href="$store.state.registerUrl">注册</a>
          </div>
        </div>
        <el-dropdown class="f-login" v-if="hasLogin == 1">
          <span class="el-dropdown-link flex flex_align_c">
            {{ role == 1 ? "客户中心" : "工厂中心"
            }}<span class="el-icon-caret-bottom el-icon--right"></span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <a href="/a/login?yzType=2">个人中心</a>
            </el-dropdown-item>
      
           
            <el-dropdown-item divided
              ><a href="/a/logout?yzType=1">退出登录</a></el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div v-if="isWap" class="header-main-m">
      <div class="bj"></div>
      <div class="show">
        <div class="l">
          <div class="logo">
            <a href="/">
              <img src="@/assets/images/public/logo.png" alt="" />
            </a>
          </div>
          <!-- <div class="location">
            <el-popover placement="bottom" width="300" trigger="click">
              <el-row :gutter="5" class="location-list">
                <el-col :span="6" v-for="item in locationList" :key="item">
                  <div
                    :class="['item', location == item ? 'on' : '']"
                    @click="location = item"
                  >
                    {{ item }}
                  </div>
                </el-col>
              </el-row>
              <div slot="reference" class="text">
                {{ location }} <span class="el-icon-caret-bottom"></span>
              </div>
            </el-popover>
          </div> -->
        </div>
        <div class="menu-btn" @click="isOpen = true">
          <i class="iconfont icon-ego-menu"></i>
          <i class="iconfont icon-cha1"></i>
        </div>
      </div>
      <div :class="['menu', isOpen ? 'on' : '']">
        <i class="iconfont icon-cha1" @click="isOpen = false"></i>
        <div class="login" v-if="hasLogin == 0">
          <span class="el-icon-user-solid"></span>
          <div>
            <a :href="$store.state.loginUrl">登录</a> /
            <a :href="$store.state.registerUrl">注册</a>
          </div>
        </div>
        <div class="user-menu" v-if="hasLogin == 1">
          <a href="/a/login?yzType=2">个人中心</a>
          <a href="/a/index?yzType=2#/a/shop/yzTaskOrder/list#需求订单">
            我的订单
          </a>
          <a href="/a/logout?yzType=1">退出登录</a>
        </div>
        <div class="list">
          <div
            :class="['item', hasActive(item.url) ? 'on' : '']"
            v-for="item in menuList"
            :key="item.id"
          >
            <a :href="item.url">{{ item.title }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      location: "全国",
      locationList: [
        "长春",
        "沈阳",
        "哈尔滨",
        "北京",
        "上海",
        "深圳",
        "广州",
        "杭州",
        "天津",
      ],
      menuList: [
        { id: 1, title: "首页", url: "/" },
        { id: 2, title: "供需大厅 ", url: "/home" },
        { id: 3, title: "企业大厅", url: "/company/list" },
        { id: 4, title: "平台资讯", url: "/supplier/list" },
        { id: 5, title: "惠享学院", url: "/support" },
        { id: 7, title: "展会赛事", url: "/match/list" },
        // { id: 8, title: "专家库", url: "/zhuanjia/list" },
        { id: 9, title: "关于我们", url: "/me" },
      ],
      keyword: "",
      hasLogin: false,
      isDown: false,
      userInfo: {},
      title: "客户中心",
      role: 1,
      select: "1",
    };
  },
  computed: {
    isWap() {
      return this.$store.state.isWap;
    },
  },
  methods: {
    gokeydown(e) {
      // 兼容FF和IE和Opera
      var theEvent = e || window.event;
      var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
      if (code == 13) {
        //回车执行查询
        this.goSearch();
      }
    },
    goSearch() {
    
      if (this.select == 1) {
        if (this.$route.fullPath.indexOf("/home") >= 0) {
          this.$store.commit("setKeyword", this.keyword);
        }
        this.$router.push({ path: "/home?value=" + this.keyword });
      } else if (this.select == 2) {
        if (this.$route.fullPath.indexOf("/company/list") >= 0) {
          this.$store.commit("setKeyword", this.keyword);
        }
        this.$router.push({ path: "/company/list?value=" + this.keyword });
      } else if (this.select == 3) {
        if (this.$route.fullPath.indexOf("/supplier/list") >= 0) {
          this.$store.commit("setKeyword", this.keyword);
        }
        this.$router.push({ path: "/supplier/list?value=" + this.keyword });
      } else if (this.select == 4) {
        if (this.$route.fullPath.indexOf("/support") >= 0) {
          this.$store.commit("setKeyword", this.keyword);
        }
        this.$router.push({ path: "/support?value=" + this.keyword });
      } else if (this.select == 5) {
        if (this.$route.fullPath.indexOf("/match/list") >= 0) {
          this.$store.commit("setKeyword", this.keyword);
        }
        this.$router.push({ path: "/match/list?value=" + this.keyword });
      }
    },
    goUrl(item) {
      this.$router.push({ path: item.url });
    },
    hasActive(url) {
      if (this.$route.fullPath == "/") {
        return this.$route.fullPath == url;
      } else if (url != "/") {
        return this.$route.fullPath.indexOf(url) >= 0;
      }
    },
  },
  mounted() {
    let _this = this;
    $(".input-with-select").bind("keydown", function (e) {
      // 兼容FF和IE和Opera
      _this.gokeydown(e);
    });
    this.keyword = this.$route.query.value || "";
    if (this.$route.fullPath.indexOf("/supplier") >= 0) {
      this.select = "3";
    } else if (this.$route.fullPath.indexOf("/service") >= 0) {
      this.select = "2";
    } else if (this.$route.fullPath.indexOf("/servers") >= 0) {
      this.select = "1";
    }
    this.$api.get("/api/v1/loginUserCheck").then((res) => {
      this.hasLogin = res.code;
      if (res.data) {
        this.userInfo = res.data;
        if (res.data.roleCode) {
          if (res.data.roleCode.indexOf("member") >= 0) {
            //客户
            this.role = 1;
          } else if (res.data.roleCode.indexOf("shoper") >= 0) {
            //服务商
            this.role = 2;
          } else if (
            res.data.roleCode.indexOf("member") >= 0 &&
            res.data.roleCode.indexOf("shoper") >= 0
          ) {
            //多重角色
            this.role = 3;
          }
        }
        this.userInfo.roleType = this.role;
        this.$store.commit("setUserInfo", this.userInfo);
        this.$storage.setItem("userInfo", this.userInfo);
      }
    });
    this.$store.commit("anime");
    let htmlObj = document.getElementsByTagName("html")[0];
    window.onscroll = () => {
      this.$store.commit("anime");
      if (htmlObj.scrollTop > 50) {
        this.isDown = true;
      } else {
        this.isDown = false;
      }
    };
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/headers.scss";
</style>