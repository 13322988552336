import {
	staticService
} from "./api.js";
import storage from "./storage.js";
const apiArray = ['getYzTaskCategoryList','getAreaJson']
//获取字典
async function getDict(name) {
	let data = {};
	if(typeof name === 'string'){
		let $dict = storage.getItem(`dict_${name}`);
		if ($dict) {
			data[name] = $dict;
		} else {
			await staticService.get(
				apiArray.includes(name)?`/api/v1/${name}.json`:`/api/v1/getDictDataList-${name}.json`, {}, {
					load: true
				}).then(res => {
				data[name] = res;
				storage.setItem(`dict_${name}`, res, 600000);
			});
		}
	}else{
		data = await getAllDict(name);
	}
	return data;
}
async function getAllDict(names,i=0,data={}) {
	let name = names[i];
	let $dict = storage.getItem(`dict_${name}`);
	if ($dict) {
		data[name] = $dict;
	} else {
		await staticService.get(
			apiArray.includes(name)?`/api/v1/${name}.json`:`/api/v1/getDictDataList-${name}.json`, {}, {
				load: true
			}).then(res => {
			data[name] = res;
			storage.setItem(`dict_${name}`, res, 600000);
		});
	}
	if(names.length-1==i){
		return data;
	}else{
		i++;
		return await getAllDict(names,i,data);
	}
}
export default getDict;