<template>
  <div
    :class="['competition-header', this.$route.fullPath == '/home' ? '' : '']"
  >
    <div :class="['header-main', isDown ? 'on' : '']">
      <div class="bj"></div>
      <div class="content anime flex_b flex_align_c">
        <div class="menu" v-if="(isWap && showMenu) || !isWap">
          <a
            :href="item.url"
            :class="['item', hasActive(item.url) ? 'on' : '']"
            v-for="item in menuList"
            :key="item.id"
            >{{ item.title }}</a
          >
        </div>
        <div class="login flex flex_align_c" v-if="hasLogin == 0">
          <span class="el-icon-user-solid"></span>
          <div>
            <a @click="gotoLogin()">登录</a> /
            <a @click="gotoRegisterUser()">注册</a>
          </div>
        </div>
        <el-dropdown class="f-login" v-if="hasLogin == 1">
          <span class="el-dropdown-link flex flex_align_c">
            {{ role == 1 ? "客户中心" : "工厂中心"
            }}<span class="el-icon-caret-bottom el-icon--right"></span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <a href="/a/login?yzType=2">个人中心</a>
            </el-dropdown-item>
        
          
            <el-dropdown-item divided
              ><a href="/a/logout?yzType=1">退出登录</a></el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <div class="menu-btn" v-if="isWap" @click="showMenu = !showMenu">
          <i v-if="!showMenu" class="iconfont icon-ego-menu"></i>
          <i v-if="showMenu" class="iconfont icon-cha1"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMenu: false,
      location: "全国",
      locationList: [
        "全国",
        "长春",
        "沈阳",
        "哈尔滨",
        "北京",
        "上海",
        "深圳",
        "广州",
        "杭州站",
      ],
      menuList: [
        { id: 1, title: "首页", url: "/competition" },
        { id: 2, title: "赛事动态", url: "/competition/news" },
        // { id: 3, title: "评审专家团", url: "/competition/specialist" },
        { id: 4, title: "作品展示", url: "/competition/show" },
        { id: 5, title: "报名流程", url: "/competition/flow" },
      ],
      keyword: "",
      hasLogin: false,
      isDown: false,
      userInfo: {},
      title: "客户中心",
      role: 1,
    };
  },
  computed: {
    isWap() {
      return this.$store.state.isWap;
    },
  },
  methods: {
    goUrl(item) {
      this.$router.push({ path: item.url });
    },
    gotoLogin() {
      var redirectUrl = window.location.href;

      window.location.href =
        "https://www.huixianggongye.com/a/login?yzType=2&__url=" + redirectUrl;
    },
    gotoRegisterUser() {
      var redirectUrl = window.location.href;
      window.location.href =
        "https://www.huixianggongye.com//account/registerUser?yzType=2&__url=" +
        redirectUrl;
    },
    hasActive(url) {
      if (this.$route.fullPath == "/competition") {
        return this.$route.fullPath == url;
      } else if (url != "/competition") {
        return this.$route.fullPath.indexOf(url) >= 0;
      }
    },
  },
  mounted() {
    this.$api.get("/api/v1/loginUserCheck").then((res) => {
      this.hasLogin = res.code;
      if (res.data) {
        this.userInfo = res.data;
        if (res.data.roleCode) {
          if (res.data.roleCode.indexOf("member") >= 0) {
            //客户
            this.role = 1;
          } else if (res.data.roleCode.indexOf("shoper") >= 0) {
            //服务商
            this.role = 2;
          } else if (
            res.data.roleCode.indexOf("member") >= 0 &&
            res.data.roleCode.indexOf("shoper") >= 0
          ) {
            //多重角色
            this.role = 3;
          }
        }
        this.userInfo.roleType = this.role;
        this.$store.commit("setUserInfo", this.userInfo);
        this.$storage.setItem("userInfo", this.userInfo);
      }
    });
    setTimeout(() => {
      this.$store.commit("anime");
    }, 300);
    let htmlObj = document.getElementsByTagName("html")[0];
    window.onscroll = () => {
      this.$store.commit("anime");
      if (htmlObj.scrollTop > 50) {
        this.isDown = true;
      } else {
        this.isDown = false;
      }
    };
  },
};
</script>
<style lang="scss">
.competition-header {
  height: 70px;
  .header-main {
    position: fixed;
    top: 0;
    left: 0;
    height: 70px;
    width: 100%;
    z-index: 999;
    background-color: $c3;
    &.on {
      background-color: fade-out($color: $c3, $amount: 0.5);
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-content: center;
      .menu {
        line-height: 70px;
        font-size: 18px;
        display: flex;
        width: 80%;
        a {
          color: #f5f5f5;
          display: block;
          width: 18%;
          margin-right: 2%;
          text-align: center;
          &.on,
          &:hover {
            background-color: $c2;
          }
        }
      }
      .login {
        font-size: 18px;
        color: #f5f5f5;
        width: 15%;

        & > span {
          margin-right: 10px;
        }

        div {
          a {
            color: #f5f5f5;
            cursor: pointer;

            &:hover {
              color: $c;
            }
          }
        }
      }

      .f-login {
        width: 100px;
        .el-dropdown-link {
          font-size: 16px;
          color: #f5f5f5;
          cursor: pointer;
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  .competition-header {
    height: 12vw;
    .header-main {
      position: fixed;
      top: 0;
      left: 0;
      height: 12vw;
      width: 100%;
      z-index: 999;
      background-color: $c3;
      &.on {
        background-color: fade-out($color: $c3, $amount: 0.5);
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
      }
      .content {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        height: 100%;
        .menu-btn {
          color: white;
          i {
            font-size: 5vw;
          }
        }
        .menu {
          line-height: 12vw;
          font-size: 4vw;
          display: flex;
          flex-wrap: wrap;
          width: 30vw;
          position: absolute;
          right: 0;
          top: 12vw;
          background-color: $c3;
          a {
            color: #f5f5f5;
            display: block;
            width: 100%;
            margin-right: 2%;
            text-align: center;
            &.on,
            &:hover {
              background-color: $c2;
            }
          }
        }
        .login {
          font-size: 4vw;
          color: #f5f5f5;
          width: 40%;

          & > span {
            margin-right: 10px;
          }

          div {
            a {
              color: #f5f5f5;
              cursor: pointer;

              &:hover {
                color: $c;
              }
            }
          }
        }

        .f-login {
          width: 30%;
          .el-dropdown-link {
            font-size: 16px;
            color: #f5f5f5;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style> 