import axios from 'axios'
import {
    Message
} from 'element-ui'
import storage from '@/common/storage';

let api = `${location.protocol}//${location.hostname}${location.port?':'+location.port:''}`;
let sapi = api;
if (window && window.config && window.config.api) {
    api = window.config.api;
    sapi = window.config.sapi;
}

const configs = configs => {
    // do something before request is sent
    let userInfo = storage.getItem("userInfo");
    if (window.config.api == "/papi") {
        configs.url += configs.url.indexOf('?') > 0 ? `&__sid=${window.config.sid}` : `?__sid=${window.config.sid}`;
    }
    // if (userInfo) {
    //     configs.headers.post.token = userInfo.token
    //     configs.headers.get.token = userInfo.token
    // }
    return configs
};
const configs2 = configs => {
    // do something before request is sent
    let userInfo = storage.getItem("userInfo");
    if (window.config.api == "/papi") {
        configs.url += configs.url.indexOf('?') > 0 ? `&__sid=${window.config.sid}` : `?v=${new Date().getTime()}&__sid=${window.config.sid}`;
    }else{
        configs.url += configs.url.indexOf('?') > 0 ? `&v=${new Date().getTime()}` : `?v=${new Date().getTime()}`;
    }
    // if (userInfo) {
    //     configs.headers.post.token = userInfo.token
    //     configs.headers.get.token = userInfo.token
    // }
    return configs
};
const error = error => {
    console.log('err' + error) // for debug
    Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
    })
    return Promise.reject(error)
}
const response = response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.result && res.result == 'login') {
        Message({
            message: res.message || 'Error',
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(new Error(res.message || 'Error'))
    } else if (res.result && res.result !== 'true' && !response.config.mum) {
        Message({
            message: res.message || 'Error',
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(new Error(res.message || 'Error'))
    } else {
        return res
    }
}
// create an axios instance
const service = axios.create({
    baseURL: api, // url = base url + request url
    timeout: 500000 // request timeout

})
// service.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
// response interceptor
service.interceptors.response.use(
    response,
    error
)
// request interceptor
service.interceptors.request.use(
    configs,
    error
)
// create an axios instance
const staticService = axios.create({
    baseURL: sapi, // url = base url + request url
    timeout: 50000 // request timeout
})
// response interceptor
staticService.interceptors.response.use(
    response,
    error
)
// request interceptor
staticService.interceptors.request.use(
    configs2,
    error
)
export {
    service,
    staticService
}