/**
 * @function 计算时间差
 * @start_time 开始时间
 * @end_time 结束时间
 * @format 输出格式，默认 剩余DD天hh时mm分ss秒SSSS毫秒
 * @units 输出时间单位，默认 无，可选 天、时、分、秒、毫秒
 */
const getTimeDiff = (o = {}) => {
    let start_time = o.start_time || new Date()
    let end_time = o.end_time || new Date()
    let units = o.units
    let format = o.format || '剩余DD天hh时mm分ss秒SSS毫秒'
    let timeDiff = end_time.getTime() - start_time.getTime();
    //取天的余数
    let _time_d = timeDiff % 86400000;
    //获取相差天数
    let _d = (timeDiff - _time_d) / 86400000;
    //优先级 输出单位优先
    if (units) {
        let run = "";
        switch (units) {
            case '天':
                run = _d;
                break;
            case '时':
                run = (timeDiff - (timeDiff % 3600000)) / 3600000;
                break;
            case '分':
                run = (timeDiff - (timeDiff % 60000)) / 60000;
                break;
            case '秒':
                run = (timeDiff - (timeDiff % 1000)) / 1000;
                break;
            case '毫秒':
                run = timeDiff;
                break;
        }
        return run;
    } else {
        //取小时的余数
        let _time_h = _time_d % 3600000;
        //获取相差小时
        let _h = (_time_d - _time_h) / 3600000;
        //取分钟的余数
        let _time_m = _time_h % 60000;
        //获取相差分钟
        let _m = (_time_h - _time_m) / 60000;
        //取秒的余数
        let _time_s = _time_m % 1000;
        //获取相差秒
        let _s = (_time_m - _time_s) / 1000;
        //取毫秒的余数
        let _time_S = _time_s % 1;
        //获取相差毫秒
        let _S = (_time_s - _time_S) / 1;
        let _date = {
            "D+": _d,
            "h+": _h,
            "m+": _m,
            "s+": _s,
        };
        let _fmt = format;
        if (/(S+)/.test(_fmt)) {
            let _value = (_S + "").substr(4 - RegExp.$1.length);
            if (String(_value).length == 1) {
                _value = '00' + _value;
            } else if (String(_value).length == 2) {
                _value = '0' + _value;
            }
            _fmt = _fmt.replace(RegExp.$1, _value);
        }
        for (let item in _date) {
            //判断是否存在关键字
            if (new RegExp("(" + item + ")").test(_fmt)) {
                //将对应的关键字换成对应的时间
                let _value = (RegExp.$1.length == 1) ? (_date[item]) : (("00" + _date[item]).substr(("" + _date[item]).length));
                _fmt = _fmt.replace(RegExp.$1, _value);
            }
        }
        return _fmt;
    }
}

/** 
 * @function 格式化时间
 * @format 输出格式，默认YYYY-MM-DD hh:mm:ss
 * @date 时间对象，默认当前时间
 * @isGetJson 是否拆分成json格式
 */
const getFormat = (o = {}) => {
    let format = o.format || 'YYYY-MM-DD hh:mm:ss'
    let date = typeof o.date === 'string'?new Date(o.date.replaceAll('-','/')):o.date || new Date()
    let isGetJson = o.isGetJson;
    let _json = {};
    let _date = {
        "M+": date.getMonth() + 1, //月份   
        "D+": date.getDate(), //日   
        "h+": date.getHours(), //小时   
        "m+": date.getMinutes(), //分   
        "s+": date.getSeconds(), //秒   
        "Q+": Math.floor((date.getMonth() + 3) / 3), //季度   
        "S": date.getMilliseconds() //毫秒   
    };
    let _fmt = format;
    if (/(Y+)/.test(_fmt)) {
        let _value = (date.getFullYear() + "").substr(4 - RegExp.$1.length);
        _fmt = _fmt.replace(RegExp.$1, _value);
        _json['Y'] = _value;
    }
    for (let item in _date) {
        //判断是否存在关键字
        if (new RegExp("(" + item + ")").test(_fmt)) {
            //将对应的关键字换成对应的时间
            let _value = (RegExp.$1.length == 1) ? (_date[item]) : (("00" + _date[item]).substr(("" + _date[item]).length));
            _fmt = _fmt.replace(RegExp.$1, _value);
            _json[item.replace('+', '')] = _value;
        }
    }
    if (isGetJson) {
        return _json;
    } else {
        //返回将格式替换成时间的字符串
        return _fmt;
    }
}

let hiddens = (str, frontLen, endLen) => { //str：要进行隐藏的变量  frontLen: 前面需要保留几位    endLen: 后面需要保留几位
    if (str && str.length > frontLen + endLen) {
        var len = str.length - frontLen - endLen;
        var xing = '';
        for (var i = 0; i < len; i++) {
            xing += '*';
        }
        return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
    } else {
        return str;
    }
}
//字符串转base64
function encode(str) {
    // 对字符串进行编码
    var encode = encodeURI(str);
    // 对编码的字符串转化base64
    var base64 = btoa(encode);
    return base64;
}

// base64转字符串
function decode(base64) {
    // 对base64转编码
    var decode = atob(base64);
    // 编码转字符串
    var str = decodeURI(decode);
    return str;
}

function getFormData(obj,formData=new FormData()) {
    Object.keys(obj).forEach(item => {
        if (Object.prototype.toString.call(obj[item]) == '[object Array]') {
            obj[item].forEach((items, indexs) => {
                Object.keys(items).forEach((items2, indexs2) => {
                    formData.append(`${item}[${indexs}].${items2}`, items[items2]);
                })
            })
        }else if (Object.prototype.toString.call(obj[item]) == '[object Object]') {
            formData = getFormData(obj[item],formData);
        } else {
            formData.append(item, obj[item]);
        }

    })
    return formData;
}
//加 
function floatAdd(arg1, arg2) {
    var r1, r2, m;
    try {
        r1 = arg1.toString().split(".")[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = arg2.toString().split(".")[1].length
    } catch (e) {
        r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (arg1 * m + arg2 * m) / m;
}

//减 
function floatSub(arg1, arg2) {
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = arg2.toString().split(".")[1].length
    } catch (e) {
        r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2));
    //动态控制精度长度 
    n = (r1 >= r2) ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

//乘 
function floatMul(arg1, arg2) {
    var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length
    } catch (e) {}
    try {
        m += s2.split(".")[1].length
    } catch (e) {}
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}


//除 
function floatDiv(arg1, arg2) {
    var t1 = 0,
        t2 = 0,
        r1, r2;
    try {
        t1 = arg1.toString().split(".")[1].length
    } catch (e) {}
    try {
        t2 = arg2.toString().split(".")[1].length
    } catch (e) {}

    r1 = Number(arg1.toString().replace(".", ""));

    r2 = Number(arg2.toString().replace(".", ""));
    return (r1 / r2) * Math.pow(10, t2 - t1);
}
const showDate = (obj) => {
    obj = obj.replace(/-/g, "/");
    obj.split(':').length < 3 ? obj + ':00' : null;
    let o = {};
    o.start_time = new Date(obj);
    o.end_time = new Date();
    o.units = "天";
    let num = getTimeDiff(o);
    let dateStr = obj;
    if (num == 0) {
        o.units = "时";
        num = getTimeDiff(o);
        if (num == 0) {
            o.units = "分";
            num = getTimeDiff(o);
            if (num == 0) {
                dateStr = "刚刚";
            } else {
                dateStr = num + "分钟前";
            }
        } else {
            dateStr = num + "小时前";
        }
    } else if (num == -1) {
        dateStr = "昨天";
    } else {
        dateStr = getFormat({
            date: o.start_time,
            format: "YYYY-MM-DD hh:mm"
        });
    }
    return dateStr;
}
export default {
    getTimeDiff,
    getFormat,
    hiddens,
    encode,
    decode,
    getFormData,
    floatAdd,
    floatSub,
    floatMul,
    floatDiv,
    showDate
};